<template>
  <div class="vant-table">
    <table cellspacing="0" style="width:100%" class="table">
      <tr>
        <th class="th" v-for="(item, index) in option.column" :key="index">{{ item.label }}</th>
      </tr>
      <tr v-for="(item, index) in tableData" :key="index" class="list-tr">
        <td v-for="(context, i) in option.column" :key="i">{{ item[context.tableDataprop] }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name:'TableVant',
  props:{
    tableData:{
      type: Array,
      default: []
    },
    option:{
      type: Object,
      default: {}
    }
  },
  created() {}
};
</script>

<style   scoped>
.vant-table{
    box-shadow: 0px 0px 4px 0px rgba(180,180,180,0.5);
border-radius: 10px;
}

.table {
   

}
.th {
    text-align: left;
  height: 36px;
  line-height: 36px;
  font-size: 10px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #999999;
padding-left: 8px;
}
.list-tr {
    border-top: 1px solid #EAEAEA;
  height: 54px;
  line-height: 54px;
  font-size: 12px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #333333;
}

td:first-child {
    padding-left: 10px;
}
</style>

