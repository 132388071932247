<template>
  <div class="content" style="min-height:100vh">
    <navbar @asideFlagShow="asideFlagShow"></navbar>
    <div class="main">
      <div class="title">
        <div @click="back">
          <van-image width="9px" height="15px" :src="require('@/assets/cls/arrow-l.png')" />
          <span>{{$t('exchangeRecord.dhjl')}}</span>
        </div>
      </div>
      <van-tabs
        v-model="tabVal"
        color="#00A8FF"
        title-active-color="#00A8FF"
        style="padding:0 92px;margin-bottom:15px"
      >
        <van-tab :title="$t('exchangeRecord.all')"></van-tab>
        <van-tab title="USDT"></van-tab>
        <van-tab title="CLS"></van-tab>
      </van-tabs>
    <vant-table :option="option" :tableData="tableData"></vant-table>
    </div>
    <asidePopup @asideFlagShow="asideFlagShow" style="text-align:left" :asideFlag="asideFlag"></asidePopup>
  </div>
</template>
  <script>
import asidePopup from "@/components/common/asidePopup";
import vantTable from "@/components/common/vantTable";
export default {
  components: { asidePopup, vantTable },

  data() {
    return {
      asideFlag: false,
      popupShow: false,
      tabVal: 0,
      tableData: [
        {
          time: '2023-04-03 12:00',
          type: '兑换CLS',
          enter: '350 CLS',
          out: "100 USDT",
    
        },
        {
          time: '2023-04-04 12:00',
          type: '兑换CLS',
          enter: '350 CLS',
          out: "100 USDT",
    
        },
        {
          time: '2023-04-05 12:00',
          type: '兑换CLS',
          enter: '350 CLS',
          out: "100 USDT",
    
        },{
          time: '2023-04-06 12:00',
          type: '兑换CLS',
          enter: '350 CLS',
          out: "100 USDT",
    
        },
      ],
      option: {
        column: [
          {
            label:this.$t('exchangeRecord.date'),
            tableDataprop: 'time',

          },
          {
            label:this.$t('exchangeRecord.class'),
            tableDataprop: 'type'
          },
          {
            label:this.$t('exchangeRecord.lacle'),
            tableDataprop: 'enter'
          },
          {
            label:this.$t('exchangeRecord.label'),
            tableDataprop: 'out'
          },

        ]
      }
    };
  },
  methods: {
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    back(){
      this.$router.back()
    },
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置
    
  },
};
</script>
  <style lang="scss" scoped>
.main {
  padding: 13px 15px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    > div:first-child {
      display: flex;
      align-items: center;
      span {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-left: 6px;
      }
    }
  }
}
</style>